.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div {
  display: flex;
  align-items: baseline;
}
.highcharts-table-caption {
  caption-side: top;
  text-align: center;
  font-weight: 700;
  color: black;
  font-size: larger;
}
.highcharts-data-table {
  border-collapse: collapse;
  width: 100% !important;
}
.highcharts-data-table table {
  width: 100%;
}
.highcharts-data-table th {
  cursor: pointer;
  text-align: center;
}
.highcharts-data-table th,
.highcharts-data-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.cursor-pointer,.date-picker .MuiInputBase-input {
  cursor: pointer;
}

.download-csv {
  background: linear-gradient(#FFCC00, #FF9900);
  border: none;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
}

.btn-warning,.btn-primary {
  background-color: #7777c9 !important;
  color: #ffffff !important;
  border: none !important;
  text-transform: none !important;
  height: 45px !important;
}
.icon-info,.icon-danger,.icon-dark,.icon-success,.icon-warning,.icon-secondary {
  color: #fff;
  border: none;
  background-color: #333399;
}

/* reminder list */
.truncate {
  width: 200px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiple-input1 .job-input{
  width: 75% !important;
  display: inline !important;
  margin-top: 10px;
}
.multiple-input2 .job-input{
  width: 75% !important;
  display: inline !important;
  margin-top: 10px;  
}
.multiple-input1 button{
  bottom: 3px !important;
}
.multiple-input1 .btn-add{
  left:3px;
}
.multiple-input2 .btn-add{
  left:3px;
}
.multiple-input2 button{
  bottom: 18px !important;
}
.border-dark:hover{
  border: 1px solid black !important;
}
.date-picker .MuiOutlinedInput-notchedOutline{
  border: none
}

.navbar-vertical-content {
  height: 80vh;
  overflow-y: auto;
  position: sticky;
}
.fc-event{
  border: none !important;
  top: 10px;
}
.modal{
  scrollbar-width: none !important;
  box-shadow: none !important;
}
.filter .form-select:hover{
  border: 1px solid black !important;
}
.filter .form-select{
  border: 1px solid rgb(0 0 0 / 23%) !important;
  color: black !important;
}
.filter .form-control:hover{
  border: 1px solid black !important;
}
.filter .form-control{
  border: 1px solid rgb(0 0 0 / 23%) !important;
  color: black !important;
}
.filter .form-control::placeholder{
  color: black !important;
}
.form-select:hover{
  border: 1px solid black !important;
}
.form-select{
  border: 1px solid rgb(0 0 0 / 23%) !important;
  color: rgb(0 0 0 / 66%) !important;
}
.form-control::placeholder{
  color: rgb(0 0 0 / 66%)!important;
}
.form-control:hover{
  border: 1px solid black !important;
}
.form-control{
  border: 1px solid rgb(0 0 0 / 23%) !important;
  color: rgb(0 0 0 / 66%) !important;
}
.date-picker:hover{
  border: 1px solid black !important;
}
.MuiInputBase-input{
  color: rgb(0 0 0 / 66%) !important;
}
.filter .MuiInputBase-input{
  color: black !important;
}