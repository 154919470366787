.attendance-summary-card {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.attendance-summary-title {
  font-size: 1.25rem;
  margin-bottom: 15px;
  color: #333;
}

.attendance-summary-details {
  display: flex;
  justify-content: space-between;
}

.attendance-summary-left,
.attendance-summary-right {
  width: 48%;
}

.attendance-summary-item {
  margin-bottom: 10px;
}

.attendance-summary-label {
  font-weight: 600;
  color: #555;
}

.attendance-summary-value {
  font-weight: 700;
  color: #333;
  margin-left: 8px;
}
