.emp-name {
  margin: auto 0px;
  color: rgb(61, 77, 105);
  font-size: 13.5px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  white-space: nowrap;
}

.activity-value {
  margin: auto 0px;
  color: rgb(61, 77, 105);
  font-size: 15px;
  font-weight: 700;
}
.activity-label {
  margin: auto 0px;
  color: rgba(61, 77, 105, 0.7);
  font-size: 12px;
  font-weight: 500;
}

.task-summary {
  margin: 0px;
  color: rgb(31, 41, 57);
  font-size: 13px;
  font-weight: 600;
}

.task-summary-value {
  margin: 0px;
  color: rgb(61, 77, 105);
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 230px;
  cursor: pointer;
}

/*  */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.slick-slide img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* Gallery styles */
.gallery .slick-list {
  box-shadow: 0px 3px 10px 0px rgba(17, 19, 19, 0.15);
  border-radius: 0.5rem;
}

.gallery .slick-list img {
  display: block;
  filter: contrast(1.2);
  cursor: grab;
}

ul.slick-dots {
  display: flex !important;
  justify-content: center;
  gap: 5px;
  align-items: center;
  padding: 0;
  list-style-type: none !important;
}

.close-button {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 24px;
  background: #333399;
  color: #ffffff;
  cursor: pointer;
  border-radius: 100%;
  border: 0;
  padding: 0px 10px;
  z-index: 9999;
}

.slick-track {
  display: flex;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.grid-item {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.image-container {
  position: relative;
}
.image-hover-options {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.514);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.image-container:hover .image-hover-options {
  display: flex;
}
.image-hover-options button {
  margin: 5px;
  background: transparent;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.image-hover {
  transition: transform 0.3s ease;
}
.image-hover:hover {
  transform: scale(1.05);
}
@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
